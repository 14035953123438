import React, { useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useNavigate, Outlet } from "react-router-dom";
import ImageLogo from '../../components/Logo/logo.png'
import Layout from '../../layout/fullpage';
import { ToastContainer, toast } from 'react-toastify';
import { Media, MediaGroup, Image, OverlineTitle, Logo } from '../../components';
import { updateToken } from '../../api';
import axios from 'axios';

const AuthLoginPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log(apiUrl)
    const token = localStorage.getItem("token");
    // console.log(token)
    const navigate = useNavigate()
    const handleLogin = async (event) => {
        event.preventDefault();

        const requestData = {
            email: event.target.email.value,
            password: event.target.password.value
        };

        try {
            const response = await axios.post(`${apiUrl}/api/Admin/login`, requestData);

            console.log(response);
            
            if (response.data.message === "login successfully") {
                // const data = await response.json();
                localStorage.setItem('token', response.data.token);
                updateToken(response.data.token);
                toast.success('Login successful!');
                navigate('/home-ecommerce');
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            console.error(error);
            toast.error('Login failed. Please check your credentials.');
        }
    };



    // useEffect(() => {
    //     if(token){
    //         console.log("hi")
    //         const currentUrl = window.location.href;
    //         navigate()
    //     }
    // },[window.location.href])


    return (
        (!token ?
            <>
                <Layout title="Login" centered>
                    <div className="container p-2 p-sm-4">
                        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                            <Row className="g-0 flex-lg-row-reverse">
                                <Col lg="5">
                                    <Card.Body className="h-100 d-flex flex-column justify-content-center">
                                        <div className="nk-block-head text-center">
                                            <div className="nk-block-head-content">
                                                <h3 className="nk-block-title mb-1">Login to Account</h3>
                                                <p className="small">To begin the journey, please sign in to your account.</p>
                                            </div>
                                        </div>
                                        <Form onSubmit={handleLogin}>
                                            <Row className="gy-3">
                                                <Col className="col-12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="email">Email</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="email" id="email" placeholder="Enter email" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="col-12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="password">Password</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="password" id="password" placeholder="Enter password" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="col-12">
                                                    <div className="d-flex flex-wrap justify-content-end">
                                                        {/* <Form.Check 
                                                        className="form-check-sm"
                                                        type="checkbox"
                                                        id="rememberMe"
                                                        label="Remember Me"
                                                    /> */}
                                                        <Link to="/auths/auth-reset" className="small">Forgot Password?</Link>
                                                    </div>
                                                </Col>
                                                <Col className="col-12">
                                                    <div className="d-grid">
                                                        <Button type="submit">Login to account</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        {/* <div className="my-3 text-center">
                                        <OverlineTitle className="overline-title-sep"><span>OR</span></OverlineTitle>
                                    </div>
                                    <Row className="g-2">
                                        <Col xxl="6">
                                            <Button href="#auth" variant="outline-light" className="w-100">
                                                <Image src="/images/icon/d.png" alt="" className="icon"/>
                                                <span className="fw-medium">Continue with Google</span>
                                            </Button>
                                        </Col>
                                        <Col xxl="6">
                                            <Button href="#auth" variant="outline-light" className="w-100">
                                                <Image src="/images/icon/b.png" alt="" className="icon"/>
                                                <span className="fw-medium">Continue with Facebook</span>
                                            </Button>
                                        </Col>
                                    </Row> */}
                                        {/* <div className="text-center mt-4">
                                        <p className="small">Don't have an account? <Link to="/auths/auth-register">Register</Link></p>
                                    </div> */}
                                    </Card.Body>
                                </Col>
                                <Col lg="7">
                                    <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-center">
                                        <div className="mask mask-1"></div>
                                        <div className="brand-logo">
                                            {/* <Logo /> */}
                                            <img src={ImageLogo} />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-11">
                                                <div className="mt-4">
                                                    <div className="h1 title mb-3">Secure Access Portal</div>
                                                    <p>Enter your credentials to access the platform securely. Safeguarding your information is our top priority.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Layout>
            </> :
            <Outlet />
        )
    )
}

export default AuthLoginPage;
